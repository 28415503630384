import { useState, useEffect } from "react";

export const useMouseOver = () => {
  const [isMouseOver, setIsMouseOver] = useState(false);
  const isClient = typeof window === "object";

  useEffect(() => {
    const handleMouseOver = () => {
      setIsMouseOver(true);
    };

    /* just trigger this so that the initial state
      is updated as soon as the component is mounted
      related: https://stackoverflow.com/a/63408216
    */
    if (isClient) {
      window.addEventListener("mouseover", handleMouseOver);
      return () => {
        window.removeEventListener("mouseover", handleMouseOver);
      };
    }
  }, [isClient]);

  return isMouseOver;
};
