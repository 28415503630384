import { useState, useEffect } from "react";

const useScrollY = () => {
  const [scrollY, setScrollY] = useState(0);
  const isClient = typeof window === "object";

  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };

    /*just trigger this so that the initial state
      is updated as soon as the component is mounted
      related: https://stackoverflow.com/a/63408216
    */
    if (isClient) {
      handleScroll();

      window.addEventListener("scroll", handleScroll);
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isClient]);

  return scrollY;
};

export default useScrollY;
