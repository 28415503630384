import React from "react";
import { Lottie } from "@crello/react-lottie";
import useScrollY from "../../components/customHooks/useScrollY";
import Image from "next/legacy/image";
import { useMouseOver } from "../../components/customHooks/useMouseOver";
import { useMediaQuery } from "@mui/material";

const CardAnimation = (props) => {
  const { cardName, width, height } = props;
  const scrollY = useScrollY();
  const isMouseOver = useMouseOver();
  const [paused, setPaused] = React.useState(true);
  const downOf380 = useMediaQuery((theme) => theme.breakpoints.down(380));
  const defaultWidth = downOf380 ? 320 : 350;

  const getAnimationRouteWithoutExtension = () => `/animations/${cardName}`;

  const config = {
    loop: true,
    autoplay: true,
    path: getAnimationRouteWithoutExtension() + ".json",
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div
      onMouseOver={() => setPaused(true)}
      onFocus={() => setPaused(true)}
      onMouseOut={() => setPaused(false)}
      onBlur={() => setPaused(false)}
    >
      {scrollY > 0 || isMouseOver ? (
        <Lottie
          config={config}
          height={height || "auto"}
          width={width || defaultWidth}
          isPaused={paused}
          aria-label="card animation"
        />
      ) : (
        <Image
          width={384}
          height={288}
          src={getAnimationRouteWithoutExtension() + ".png"}
          alt="logo"
        />
      )}
    </div>
  );
};

export default CardAnimation;
